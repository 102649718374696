






















import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { CustomerPaymentInfoProperties } from "./CustomerPaymentInfoView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CustomerOrderDetailService from "@/services/CustomerOrderDetailService";

@Component({
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 1,
            recordCount: 10,
            recordCounts: [10, 20, 30, 40, 50],
            loading: false,
        },
        details: []
    })
})
export default class CustomerPaymentCustomerOrderDetailView extends Vue {
    @Prop() private customerPaymentInfo: CustomerPaymentInfoProperties;
    @Prop() private customerOrder: any;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerOrderDetailService = new CustomerOrderDetailService();

    public get headers() {
        return [
            {
                text: this.$t("text.product-code"),
                value: "productCode",
                width: "14%"
            },
            {
                text: this.$t("text.product-name"),
                value: "productName"
            },
            {
                text: this.$t("text.comment-message"),
                value: "comment.message",
                width: "25%"
            },
            {
                text: this.$t("text.quantity"),
                value: "quantity",
                width: "12%",
                align: "right"
            },
            {
                text: this.$t("text.price"),
                value: "price",
                width: "12%",
                align: "right"
            },
            {
                text: this.$t("text.total-amount"),
                value: "totalAmount",
                width: "15%",
                align: "right"
            }
        ];
    }

    public get footerProps() {
        return {
            "items-per-page-options": this.$data.table.recordCounts
        };
    }

    public formatPrice(v: any) {
        return (v ?? 0).toFixed(2);
    }

    public formatTotalAmount(v: any) {
        return (v ?? 0).toFixed(2);
    }

    public created() {
        this.load();
    }

    public async load() {
        try {
            if (this.customerOrder.id) {
                const r = await this.customerOrderDetailService.get({ 
                    customerOrderId: this.customerOrder.id 
                });
                const details = r.data.customerOrderDetails;
                this.$data.details = details.sort((lhs, rhs) => rhs.id - lhs.id);
            } else {
                this.$data.details = [];
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }
}
